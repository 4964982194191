import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Heading from '$ui/heading';
import Input from '$ui/input';
import Button from '$ui/button';
import Container from '$ui/container';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const forgotPasswordSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Please provide your phone number')
});

const ForgotPasswordPage = () => {
  const handleSubmit = useCallback(async values => {
    try {
      toast.success('Password reseted  successfully!');
      navigate('/');
    } catch (err) {
      toast.error(err.response?.data.message || 'Network Error');
    }
  }, []);

  return (
    <Container display='flex' className='bg-primary phone:px-4'>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={forgotPasswordSchema}
        initialValues={{ phone: '' }}>
        {formik => (
          <form
            onSubmit={formik.handleSubmit}
            className='w-[25rem] py-10 px-10 bg-gray-100 space-y-5 rounded-xl shadow-2xl phone:w-4/5'>
            <Heading variant='tertiary'> forgot pasword</Heading>

            <Input
              name='phone'
              type='tel'
              placeholder='Phone number'
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && formik.errors.phone}
            />

            <Button
              variant='filled'
              type='submit'
              className='w-full mt-8'
              isLoading={formik.isSubmitting}>
              Get password reset link
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default ForgotPasswordPage;
